import React, { CSSProperties } from 'react'
import { useSnapCarousel } from 'react-snap-carousel'
import * as css from './ImageSlider.css'
import { ouTheme, themeVars } from 'atomic/styles/theme.css'
import Image from 'next/image'

const styles = {
  itemSnapPoint: {
    scrollSnapAlign: 'start',
  },
  nextPrevButtonDisabled: { opacity: 0.3 },
} satisfies Record<string, CSSProperties>

interface ImageSliderProps<T> {
  readonly items: T[]
  readonly renderItem: (props: ImageSliderRenderItemProps<T>) => React.ReactElement<ImageSliderItemProps>
  readonly imageGap?: boolean
}

interface ImageSliderRenderItemProps<T> {
  readonly item: T
  readonly isSnapPoint: boolean
}

export function ImageSlider<T>({ items, renderItem, imageGap }: ImageSliderProps<T>): JSX.Element {
  const { scrollRef, pages, activePageIndex, prev, next, snapPointIndexes } = useSnapCarousel()
  return (
    <div className={css.root}>
      <ul className={css.scroll} ref={scrollRef} style={{ gap: imageGap ? themeVars.spaces.s : 0 }}>
        {items.map((item, i) =>
          renderItem({
            item,
            isSnapPoint: snapPointIndexes.has(i),
          })
        )}
      </ul>
      <div className={css.controls}>
        <button
          className={css.nextPrevButton}
          style={{
            ...(activePageIndex === 0 ? styles.nextPrevButtonDisabled : {}),
          }}
          onClick={() => prev()}
        >
          <Image
            fill
            src={'/icons/prev-bracket.svg'}
            alt={'Previous image bracket'}
            sizes={`(max-width: ${ouTheme.breakpoints.tablet}) 100vw, 50vw`}
            loading="lazy"
            fetchPriority="low"
          />
        </button>

        <button
          className={css.nextPrevButton}
          style={{
            ...(activePageIndex === pages.length - 1 ? styles.nextPrevButtonDisabled : {}),
          }}
          onClick={() => next()}
        >
          <Image
            fill
            src={'/icons/next-bracket.svg'}
            alt={'Next image bracket'}
            sizes={`(max-width: ${ouTheme.breakpoints.tablet}) 100vw, 50vw`}
            loading="lazy"
            fetchPriority="low"
          />
        </button>
      </div>
    </div>
  )
}

interface ImageSliderItemProps {
  readonly isSnapPoint: boolean
  readonly children?: React.ReactNode
}

export const ImageSliderItem = ({ isSnapPoint, children }: ImageSliderItemProps): JSX.Element => (
  <li
    className={css.item}
    style={{
      ...(isSnapPoint ? styles.itemSnapPoint : {}),
      position: 'relative',
    }}
  >
    {children}
  </li>
)
